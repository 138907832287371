import { Subheading } from '../../components/heading.jsx'
import { Text, Strong } from '../../components/text.jsx'
import { Button } from '../../components/button.jsx'
import {
  ArrowLeftStartOnRectangleIcon,
  LightBulbIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid/index.js'
import Loader from '../../components/loader.jsx'
import { Badge } from '../../components/badge.jsx'
import clsx from 'clsx'
import {
  QuestionMarkCircleIcon,
  NewspaperIcon,
  RocketLaunchIcon,
  BoltSlashIcon,
} from '@heroicons/react/24/outline/index.js'

const LensPersona = ({ name, description, selected, onSelect }) => {
  const iconMap = {
    Newscaster: NewspaperIcon,
    Hypebeast: RocketLaunchIcon,
    Critic: BoltSlashIcon,
  }
  const Icon = iconMap[name]
  return (
    <div
      className={clsx(
        'rounded-xl p-4 animate-fadeIn grid mt-2 mb-3',
        selected === name
          ? 'border-2 border-blue-500'
          : 'border-2 border-zinc-200 dark:border-zinc-700',
        'hover:cursor-pointer hover:bg-zinc-100 dark:hover:bg-zinc-700',
      )}
      onClick={onSelect}
    >
      <div className={clsx('overflow-hidden flex flex-col gap-2 items-start')}>
        <div className="flex flex-col items-start gap-2">
          <Icon className={'w-10 h-10 text-black dark:text-white'} />
          <Subheading>{name}</Subheading>
        </div>
        <Text className={clsx('animate-fadeIn')}>{description}</Text>
      </div>
    </div>
  )
}

const NotReady = ({ setSelectedEngine }) => {
  return (
    <div
      className={
        'border-2 border-dashed border-zinc-200 dark:border-zinc-700 rounded-md py-[20vh] flex flex-col justify-center items-center animate-fadeIn'
      }
    >
      <Subheading>Wait, we aren't finished with the research yet!</Subheading>
      <Text>
        Add a few more research points so we can generate angles for you.
      </Text>
      <Button
        plain
        className={'mt-3'}
        onClick={() => setSelectedEngine('Research')}
      >
        <ArrowLeftStartOnRectangleIcon className="w-4 h-4 mr-1" />
        Go back
      </Button>
    </div>
  )
}

const Instructions = ({ featureFlags, toggleFeatureFlag }) => {
  const showHint =
    featureFlags?.['show_angle_hint'] === undefined
      ? true
      : featureFlags['show_angle_hint']

  if (!showHint) return null

  return (
    <div
      className={
        'hidden sm:flex flex-row items-center border-2 border-orange-200 dark:border-amber-400/80 rounded-lg p-2 animate-fadeIn mb-2'
      }
    >
      <LightBulbIcon className="w-5 h-5 ml-2 mr-4 text-orange-300 dark:text-amber-400" />
      <Text
        className={'line-clamp-3 hover:line-clamp-none italic sm:text-xs pr-2'}
      >
        We've reviewed the research and we're ready to write the angle for your
        script. You can pick which persona you'd like to use. Your choice will
        dictate how we write the script, including the tone and message.
      </Text>
      <Button
        plain
        onClick={() =>
          toggleFeatureFlag({ flag: 'show_angle_hint', value: false })
        }
      >
        <XMarkIcon className="w-9 h-9" />
      </Button>
    </div>
  )
}

const Body = ({ loading, lens }) => {
  if (loading) {
    return (
      <div className={'flex flex-col justify-center items-center w-full'}>
        <Loader className={'h-20'} />
        <Text className={'my-4'}>
          Give us a moment while we cook up an interesting angle for you.
        </Text>
      </div>
    )
  }

  if (Object.keys(lens).length === 0) {
    return (
      <div className={'py-16 text-center w-full'}>
        <Strong className="">Choose a persona.</Strong>
        <Text>
          We'll generate a compelling angle for your script once you pick a
          persona.
        </Text>
      </div>
    )
  }

  return (
    <>
      <Text className={'sm:text-xl font-semibold mt-10'}>{lens.summary}</Text>
      <Badge className={'my-4'} color={'blue'}>
        {Object.keys(lens.research).length} research points
      </Badge>
      <Text className={''}>{lens.description}</Text>
    </>
  )
}

export default function LensDetail({
  show,
  research,
  setSelectedEngine,
  lens,
  lensLoading,
  lensPersona,
  regenerateLens,
  switchLens,
  featureFlags,
  toggleFeatureFlag,
}) {
  const personas = {
    Newscaster:
      "We'll write with an objective viewpoint, capturing the interesting facts and enlightening the viewer.",
    Hypebeast:
      "We'll get your viewers excited about the topic, emphasizing the positive outcomes and fascinating implications.",
    Critic:
      "We'll take a critical view of your topic, highlighting shortcomings and potential concerns about its impact.",
  }

  if (!show) return null

  if (research < 1) {
    return <NotReady setSelectedEngine={setSelectedEngine} />
  }

  return (
    <div className={'h-full'}>
      <Instructions
        featureFlags={featureFlags}
        toggleFeatureFlag={toggleFeatureFlag}
      />
      <div className="animate-fadeIn w-full flex flex-col h-full">
        <div className={'animate-fadeIn flex flex-col sm:flex-row gap-2'}>
          {Object.entries(personas).map(([name, description]) => (
            <LensPersona
              key={name}
              name={name}
              description={description}
              selected={lensPersona}
              onSelect={() => switchLens(name)}
            />
          ))}
        </div>
        <div
          className={clsx(
            'animate-fadeIn bg-zinc-100 dark:bg-zinc-800 sm:bg-white dark:sm:bg-zinc-800 rounded-xl shadow-sm dark:shadow-lg p-4 pb-10 flex flex-col justify-start items-start h-auto sm:overflow-y-scroll',
          )}
        >
          <div
            className={
              'flex flex-col sm:flex-row sm:items-center justify-between w-full'
            }
          >
            <div className={'flex flex-row items-center'}>
              <Subheading>Angle</Subheading>
              <button
                className="group relative flex items-center"
                aria-label="Help information"
              >
                <QuestionMarkCircleIcon className="w-5 h-5 ml-2 text-zinc-400 hover:text-zinc-600 dark:text-zinc-500 dark:hover:text-zinc-300" />
                <span className="absolute top-0 left-full mb-2 mr-2 z-10 hidden group-hover:block w-80 p-2 text-xs text-zinc-600 dark:text-zinc-300 bg-white dark:bg-zinc-800 rounded-lg shadow-lg border border-zinc-200 dark:border-zinc-700">
                  Below you'll find the angle that we've chosen for the script,
                  along with a description of how we'll shape the tone and
                  message of the script. If you don't like the angle, you can
                  choose a different persona above and we'll try re-writing.
                </span>
              </button>
            </div>
            <div
              className={
                'flex flex-col sm:flex-row items-center gap-2 sm:gap-0'
              }
            >
              <Button
                outline
                onClick={regenerateLens}
                className={'sm:mr-2 w-full sm:w-auto'}
              >
                Try again
              </Button>
              <Button
                color={'blue'}
                onClick={() => setSelectedEngine('Script')}
                disabled={lensLoading || !lensPersona}
                className={'w-full sm:w-auto'}
              >
                Use this angle
              </Button>
            </div>
          </div>
          <Body loading={lensLoading} lens={lens} />
        </div>
      </div>
    </div>
  )
}
