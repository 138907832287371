import { Button } from '../../components/button.jsx'
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from '../../components/dialog.jsx'
import { Field } from '../../components/fieldset.jsx'
import { Textarea } from '../../components/textarea.jsx'
import apiClient from '../../utils/apiClient.js'
import { useState } from 'react'
import { Badge } from '../../components/badge.jsx'
import { Text } from '../../components/text.jsx'

export default function Support({ isOpen, setIsOpen }) {
  const [supportMessage, setSupportMessage] = useState('')
  const [submitLoading, setSubmitLoading] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)

  const submitSupport = () => {
    setSubmitLoading(true)
    apiClient
      .post('admin/support', { text: supportMessage })
      .then(() => {
        setSubmitSuccess(true)
        setSupportMessage('')
      })
      .finally(() => {
        setSubmitLoading(false)
        setTimeout(() => {
          setSubmitSuccess(false)
        }, 3000)
      })
  }

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <DialogTitle>Get Support</DialogTitle>
      <DialogDescription>
        We want to help you as soon as possible! Let us know what issue you're
        experiencing. The more detail, the better.
      </DialogDescription>
      <DialogBody>
        <Field>
          <Textarea
            name="support"
            placeholder="How do I switch to dark mode?"
            value={supportMessage}
            onChange={(e) => setSupportMessage(e.target.value)}
          />
        </Field>
        {submitSuccess && (
          <div className={'mt-4'}>
            <Badge color={'green'} className={'mb-1'}>
              Submitted!
            </Badge>
            <Text>
              We've received your support request. We'll reach out shortly to
              help resolve your issue.
            </Text>
          </div>
        )}
      </DialogBody>
      <DialogActions>
        <Button plain onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button onClick={submitSupport} disabled={submitLoading}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
