import { useState, useEffect, createElement } from 'react'
import { Textarea } from '../../components/textarea.jsx'
import Loader from '../../components/loader.jsx'
import { Subheading } from '../../components/heading.jsx'
import { Text } from '../../components/text.jsx'
import { Button } from '../../components/button.jsx'
import { Badge } from '../../components/badge.jsx'
import {
  ArrowLeftStartOnRectangleIcon,
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  ArrowPathIcon,
} from '@heroicons/react/24/solid/index.js'
import {
  FunnelIcon,
  ChatBubbleLeftEllipsisIcon,
  LightBulbIcon,
  TrophyIcon,
  Bars3BottomLeftIcon,
  CursorArrowRaysIcon,
} from '@heroicons/react/24/outline/index.js'
import clsx from 'clsx'
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle,
  DialogDescription,
} from '../../components/dialog.jsx'
import {
  FieldGroup,
  Field,
  Label,
  Description,
} from '../../components/fieldset.jsx'
import {
  Listbox,
  ListboxLabel,
  ListboxOption,
} from '../../components/listbox.jsx'
import { Input } from '../../components/input.jsx'

const RewriteModal = ({
  isOpen,
  setOpen,
  setComponentToRewrite,
  componentToRewrite,
  rewriteScript,
  selectedText,
}) => {
  const [instructions, setInstructions] = useState('')

  return (
    <Dialog
      open={isOpen}
      onClose={() => setOpen(false)}
      size="2xl"
      className="max-h-[80vh] overflow-auto scroll-auto pb-4"
    >
      <DialogTitle>Start a rewrite</DialogTitle>
      <DialogDescription>
        We can help you edit the script. Tell us which part you want to rewrite
        and we'll get started on it right away.
      </DialogDescription>
      <DialogBody>
        <FieldGroup>
          <Field>
            <Label>Section to rewrite</Label>
            <Listbox
              value={componentToRewrite}
              onChange={setComponentToRewrite}
            >
              <ListboxOption value="hook">
                <FunnelIcon className="w-4 h-4 mr-1 text-purple-500" />
                <ListboxLabel>Hook</ListboxLabel>
              </ListboxOption>
              <ListboxOption value="background">
                <ChatBubbleLeftEllipsisIcon className="w-4 h-4 mr-1 text-orange-500" />
                <ListboxLabel>Background</ListboxLabel>
              </ListboxOption>
              <ListboxOption value="impact">
                <LightBulbIcon className="w-4 h-4 mr-1 text-yellow-500" />
                <ListboxLabel>Impact</ListboxLabel>
              </ListboxOption>
              <ListboxOption value="closing">
                <TrophyIcon className="w-4 h-4 mr-1 text-green-500" />
                <ListboxLabel>Closing</ListboxLabel>
              </ListboxOption>
              <ListboxOption value="full">
                <Bars3BottomLeftIcon className="w-4 h-4 mr-1 text-zinc-500" />
                <ListboxLabel>Full script</ListboxLabel>
              </ListboxOption>
              <ListboxOption value="selection" disabled={selectedText === ''}>
                <CursorArrowRaysIcon className="w-4 h-4 mr-1 text-blue-500" />
                <ListboxLabel>Selected text</ListboxLabel>
              </ListboxOption>
            </Listbox>
          </Field>
          <Field>
            <Label>Instructions</Label>
            <Description>
              Optional. If you've got something in mind, let us know.
            </Description>
            <Input
              value={instructions}
              onChange={(e) => setInstructions(e.target.value)}
              placeholder="For example... make it rhyme."
            />
          </Field>
        </FieldGroup>
      </DialogBody>
      <DialogActions>
        <Button
          plain
          onClick={() => {
            setOpen(false)
            setInstructions('')
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            rewriteScript({ componentToRewrite, selectedText, instructions })
            setOpen(false)
            setInstructions('')
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const Rewrite = ({
  name,
  openModal,
  setComponentToRewrite,
  disabled = false,
}) => {
  const IconMap = {
    hook: { icon: FunnelIcon, color: 'text-purple-500' },
    background: { icon: ChatBubbleLeftEllipsisIcon, color: 'text-orange-500' },
    impact: { icon: LightBulbIcon, color: 'text-yellow-500' },
    closing: { icon: TrophyIcon, color: 'text-green-500' },
    full: { icon: Bars3BottomLeftIcon, color: 'text-zinc-500' },
    selection: { icon: CursorArrowRaysIcon, color: 'text-blue-500' },
  }

  const { icon, color } = IconMap[name]

  return (
    <div
      className={clsx(
        'bg-zinc-100 dark:bg-zinc-800 rounded-full max-w-[90%] px-4 py-1 flex flex-row items-center justify-start border-[1px] border-zinc-100 dark:border-zinc-800',
        !disabled &&
          'hover:cursor-pointer hover:bg-zinc-200 dark:hover:bg-zinc-700 transition-all duration-200 border-zinc-200 dark:border-zinc-500',
      )}
      onClick={() => {
        if (!disabled) {
          setComponentToRewrite(name)
          openModal(true)
        }
      }}
    >
      {icon &&
        createElement(icon, {
          className: clsx(
            'w-4 h-4 mr-1',
            !disabled && color,
            disabled && 'text-zinc-300 dark:text-zinc-600',
          ),
        })}
      <Text
        className={clsx(
          'ml-1 whitespace-nowrap truncate w-full',
          disabled && 'text-zinc-200 dark:text-zinc-600',
        )}
      >
        Rewrite {name}
      </Text>
    </div>
  )
}

export default function ScriptDetail({
  storyUuid,
  show,
  topic,
  body,
  setBody,
  loading,
  ready,
  getScript,
  saveScript,
  setSelectedEngine,
  rewriteScript,
}) {
  const [lastSavedText, setLastSavedText] = useState(null)
  const [pendingChanges, setPendingChanges] = useState(false)
  const [saving, setSaving] = useState(false)
  const [textHistory, setTextHistory] = useState([])
  const [textFuture, setTextFuture] = useState([])
  const [selectedText, setSelectedText] = useState('')
  const [rewriteModalOpen, setRewriteModalOpen] = useState(false)
  const [componentToRewrite, setComponentToRewrite] = useState('')

  useEffect(() => {
    setTextHistory([])
    setTextFuture([])
    setSaving(false)
    setPendingChanges(false)
    setLastSavedText(null)
  }, [storyUuid])

  useEffect(() => {
    if (show && ready && body === '' && !loading) {
      getScript({ regenerate: true })
    }
  }, [show])

  useEffect(() => {
    setPendingChanges(true)

    const timer = setTimeout(() => {
      setLastSavedText(body)
      if (lastSavedText && body !== lastSavedText) {
        setTextHistory([...textHistory, lastSavedText])
        setSaving(true)
        saveScript(body)
        setSaving(false)
      }
      setPendingChanges(false)
    }, 3000)

    return () => clearTimeout(timer)
  }, [body])

  const undo = () => {
    if (textHistory.length > 0) {
      const newFuture = [...textFuture, body]
      setTextFuture(newFuture)
      const newText = textHistory.pop()
      setBody(newText)
    }
  }

  const redo = () => {
    if (textFuture.length > 0) {
      const newHistory = [...textHistory, body]
      setTextHistory(newHistory)
      const newText = textFuture.pop()
      setBody(newText)
    }
  }

  if (!show) return null

  if (!ready) {
    return (
      <div
        className={
          'border-2 border-dashed border-zinc-200 dark:border-zinc-700 rounded-md py-[20vh] flex flex-col justify-center items-center animate-fadeIn text-center'
        }
      >
        <Subheading>There's more to do before writing the script!</Subheading>
        <Text>Please choose an angle so we can write a script for you.</Text>
        <Button
          plain
          className={'mt-3'}
          onClick={() => setSelectedEngine('Angle')}
        >
          <ArrowLeftStartOnRectangleIcon className="w-4 h-4 mr-1" />
          Go back
        </Button>
      </div>
    )
  }

  return (
    <div className={'rounded-lg animate-fadeIn'}>
      <RewriteModal
        isOpen={rewriteModalOpen}
        setOpen={setRewriteModalOpen}
        setComponentToRewrite={setComponentToRewrite}
        componentToRewrite={componentToRewrite}
        rewriteScript={rewriteScript}
        selectedText={selectedText}
      />
      <div
        className={
          'w-full flex flex-col sm:flex-row mb-2 justify-between gap-2 sm:gap-0'
        }
      >
        <div
          className={
            'flex flex-col sm:flex-row items-center w-full sm:w-auto gap-2 sm:gap-0'
          }
        >
          <Button
            outline
            className={'sm:mr-2 mr-0 w-full sm:w-auto'}
            disabled={textHistory.length === 0}
            onClick={undo}
          >
            <ArrowUturnLeftIcon className="w-4 h-4 mr-1" />
            Undo
          </Button>
          <Button
            outline
            disabled={textFuture.length === 0}
            onClick={redo}
            className={'sm:mr-2 mr-0 w-full sm:w-auto'}
          >
            <ArrowUturnRightIcon className="w-4 h-4 mr-1" />
            Redo
          </Button>
          <Button
            outline
            className={'sm:mr-2 mr-0 w-full sm:w-auto'}
            onClick={() => getScript({ regenerate: true })}
          >
            <ArrowPathIcon className="w-4 h-4 mr-1" />
            Rewrite script
          </Button>
        </div>
        <div
          className={
            'flex flex-col sm:flex-row items-center w-full sm:w-auto gap-2 sm:gap-0'
          }
        >
          <Badge
            className={clsx('mr-2', saving && 'animate-pulse', '')}
            color={pendingChanges ? 'yellow' : 'green'}
          >
            {saving ? 'Saving...' : pendingChanges ? 'Unsaved' : 'Saved'}
          </Badge>
          <Button
            onClick={() => {
              const blob = new Blob([body], { type: 'text/plain' })
              const url = URL.createObjectURL(blob)
              const a = document.createElement('a')
              a.href = url
              a.download = `${topic.replace(/ /g, '_')}.txt`
              a.click()
              URL.revokeObjectURL(url)
            }}
            className={'w-full sm:w-auto'}
          >
            Download
          </Button>
        </div>
      </div>
      {loading && (
        <div className={'flex flex-col justify-center items-center'}>
          <Loader className={'h-20'} />
          <Text className={'mt-5'}>
            We're writing a script for you. The creative juices are flowing.
          </Text>
        </div>
      )}
      {!loading && (
        <div className={'grid grid-cols-1 gap-2 sm:grid-cols-4'}>
          <Textarea
            className={
              'sm:col-span-3 md:h-[calc(100vh-18rem)] sm:min-h-full min-h-[50vh]'
            }
            value={body}
            onChange={(e) => setBody(e.target.value)}
            onSelect={(e) => {
              const highlightedText = e.target.value.substring(
                e.target.selectionStart,
                e.target.selectionEnd,
              )
              setSelectedText(highlightedText)
            }}
            resizable={false}
          />
          <div
            className={
              'flex flex-col justify-start items-center h-fit gap-4 py-8 border-[1px] border-zinc-200 dark:border-zinc-600 rounded-xl'
            }
          >
            <Text
              className={
                'sm:text-xs whitespace-nowrap truncate w-full text-center'
              }
            >
              <Badge color="yellow" className={'mr-2'}>
                Beta
              </Badge>
              Need help?
            </Text>
            <Rewrite
              name={'hook'}
              openModal={setRewriteModalOpen}
              setComponentToRewrite={setComponentToRewrite}
            />
            <Rewrite
              name={'background'}
              openModal={setRewriteModalOpen}
              setComponentToRewrite={setComponentToRewrite}
            />
            <Rewrite
              name={'impact'}
              openModal={setRewriteModalOpen}
              setComponentToRewrite={setComponentToRewrite}
            />
            <Rewrite
              name={'closing'}
              openModal={setRewriteModalOpen}
              setComponentToRewrite={setComponentToRewrite}
            />
            <Rewrite
              name={'full'}
              openModal={setRewriteModalOpen}
              setComponentToRewrite={setComponentToRewrite}
            />
            <Rewrite
              name={'selection'}
              openModal={setRewriteModalOpen}
              setComponentToRewrite={setComponentToRewrite}
              disabled={selectedText === ''}
            />
          </div>
        </div>
      )}
    </div>
  )
}
