import { useState, useEffect } from 'react'
import apiClient from './apiClient'

export function useApi(endpoint, options = {}, deps = []) {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const fetchData = async () => {
    try {
      setLoading(true)
      const response = await apiClient.get(endpoint, options)
      setData(response)
      setError(null)
      if (options.onSuccess) {
        options.onSuccess(response)
      }
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  const mutate = async (newData) => {
    try {
      setLoading(true)
      const response = await apiClient.post(endpoint, newData, options)
      setData(response)
      setError(null)
      if (options.onSuccess) {
        options.onSuccess(response)
      }
      return response
    } catch (err) {
      setError(err)
      throw err
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [endpoint, ...deps])

  return {
    data,
    loading,
    error,
    refetch: fetchData,
    mutate,
  }
}
