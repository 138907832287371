import { Button } from '../../components/button.jsx'
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from '../../components/dialog.jsx'
import { Field } from '../../components/fieldset.jsx'
import { Textarea } from '../../components/textarea.jsx'
import apiClient from '../../utils/apiClient.js'
import { useState } from 'react'
import { Badge } from '../../components/badge.jsx'
import { Text } from '../../components/text.jsx'

export default function Feedback({ isOpen, setIsOpen }) {
  const [feedbackMessage, setFeedbackMessage] = useState('')
  const [submitLoading, setSubmitLoading] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)

  const submitFeedback = () => {
    setSubmitLoading(true)
    apiClient
      .post('admin/feedback', { text: feedbackMessage })
      .then(() => {
        setSubmitSuccess(true)
        setFeedbackMessage('')
      })
      .finally(() => {
        setSubmitLoading(false)
        setTimeout(() => {
          setSubmitSuccess(false)
        }, 3000)
      })
  }

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <DialogTitle>Share Feedback</DialogTitle>
      <DialogDescription>
        We value your feedback. Let us know what you like, or what you'd like us
        to change.
      </DialogDescription>
      <DialogBody>
        <Field>
          <Textarea
            name="feedback"
            placeholder="I love the app!"
            value={feedbackMessage}
            onChange={(e) => setFeedbackMessage(e.target.value)}
          />
        </Field>
        {submitSuccess && (
          <div className={'mt-4'}>
            <Badge color={'green'} className={'mb-1'}>
              Received!
            </Badge>
            <Text>
              Thanks for sharing your feedback! We appreciate you taking the
              time.
            </Text>
          </div>
        )}
      </DialogBody>
      <DialogActions>
        <Button plain onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button onClick={submitFeedback} disabled={submitLoading}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
