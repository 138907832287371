import Loader from './loader.jsx'

// eslint-disable-next-line react/prop-types
export default function ProtectedRoute({ children }) {
  const token = localStorage.getItem('token')
  if (!token) {
    const clientId = import.meta.env.VITE_WORKOS_CLIENT_ID
    const redirect = import.meta.env.VITE_WORKOS_REDIRECT_URL
    const fullUrl = `https://auth.sandcastles.ai/user_management/authorize?client_id=${clientId}&redirect_uri=${redirect}&response_type=code&provider=authkit`
    window.location.href = fullUrl
    return <Loader />
  }

  return children
}
