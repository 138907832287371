import { Label, Field } from '../../components/fieldset'
import { Listbox, ListboxLabel, ListboxOption } from '../../components/listbox'

export default function Status({ value, onChange }) {
  return (
    <Field className="flex flex-row items-center gap-2">
      <Label>Status</Label>
      <Listbox
        name="status"
        defaultValue="active"
        value={value}
        onChange={onChange}
        className={'mb-3'}
      >
        <ListboxOption value="draft">
          <ListboxLabel>Draft</ListboxLabel>
        </ListboxOption>
        <ListboxOption value="complete">
          <ListboxLabel>Complete</ListboxLabel>
        </ListboxOption>
        <ListboxOption value="archived">
          <ListboxLabel>Archived</ListboxLabel>
        </ListboxOption>
      </Listbox>
    </Field>
  )
}
