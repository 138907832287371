import { SpecialInput } from '../../components/input.jsx'

export default function HeroInput({ topic, setTopic, handleChooseTopic }) {
  return (
    <div className="relative min-w-48 w-4/5 flex flex-col items-center">
      <SpecialInput
        placeholder="Describe your topic"
        value={topic}
        onChange={(e) => setTopic(e.target.value)}
        onKeyDown={(e) => {
          if (topic && e.key === 'Enter') {
            handleChooseTopic(topic)
          }
        }}
      />
      <button
        className="absolute top-1/2 right-0.5 mr-0.5 -translate-y-1/2 w-10 h-10 bg-blue-500 hover:bg-blue-500/[80%] dark:hover:bg-blue-500/[70%] text-white rounded-md flex items-center justify-center z-10 transform transition-transform"
        onClick={() => handleChooseTopic(topic)}
        disabled={!topic}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M14 5l7 7m0 0l-7 7m7-7H3"
          />
        </svg>
      </button>
    </div>
  )
}
