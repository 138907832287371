import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useApi } from '../../utils/useApi.js'
import apiClient from '../../utils/apiClient.js'
import { Avatar } from '../../components/avatar'
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from '../../components/dropdown'
import {
  Navbar,
  NavbarItem,
  NavbarLabel,
  NavbarSection,
  NavbarSpacer,
} from '../../components/navbar'
import { Button } from '../../components/button.jsx'
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarHeading,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
  SidebarSpacer,
} from '../../components/sidebar'
import { SidebarLayout } from '../../components/sidebarLayout'
import {
  ArrowRightStartOnRectangleIcon,
  ChevronUpIcon,
  Cog8ToothIcon,
  LightBulbIcon,
  ShieldCheckIcon,
} from '@heroicons/react/16/solid'
import {
  MagnifyingGlassIcon,
  QuestionMarkCircleIcon,
  PencilSquareIcon,
} from '@heroicons/react/20/solid'
import { TrashIcon } from '@heroicons/react/24/outline'
import Feedback from './feedback.jsx'
import Support from './support.jsx'
import DeleteModal from './deleteModal.jsx'
import { Input, InputGroup } from '../../components/input.jsx'
import clsx from 'clsx'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import Loader from '../../components/loader.jsx'

export default function Layout({
  children,
  refresh,
  setRefresh,
  user,
  setUser,
  userLoading,
  setUserLoading,
}) {
  const navigate = useNavigate()
  const location = useLocation()
  const pathUuid = location.pathname.startsWith('/s/')
    ? location.pathname.split('/')[2]
    : undefined
  const [supportOpen, setSupportOpen] = useState(false)
  const [feedbackOpen, setFeedbackOpen] = useState(false)
  const [uuidToDelete, setUuidToDelete] = useState('')
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [selectedUuid, setSelectedUuid] = useState(pathUuid)
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false)
  const [search, setSearch] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useState(search)

  useEffect(() => {
    apiClient.get('/auth/user').then((resp) => {
      setUser(resp)
      setUserLoading(false)
    })
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(search)
    }, 500)
    return () => clearTimeout(timer)
  }, [search])

  const { data: stories, loading } = useApi(
    `story/list?query=${debouncedSearch}`,
    {
      onSuccess: () => {
        setRefresh(false)
        const pathUuid = location.pathname.startsWith('/s/')
          ? location.pathname.split('/')[2]
          : undefined
        setSelectedUuid(pathUuid)
      },
    },
    [refresh],
  )

  const deleteStory = () => {
    apiClient.delete(`story/?story_uuid=${uuidToDelete}`)
    setRefresh(true)
    navigate('/')
  }

  if (userLoading) {
    return <Loader />
  }

  return (
    <SidebarLayout
      sidebarCollapsed={sidebarCollapsed}
      setSidebarCollapsed={setSidebarCollapsed}
      navbar={
        <Navbar>
          <NavbarSection>
            <NavbarItem href={'/'} onClick={() => setSelectedUuid(undefined)}>
              <Avatar src="/sandcastles-logo-icon.png" />
              <NavbarLabel className="font-semibold">Sandcastles</NavbarLabel>
            </NavbarItem>
          </NavbarSection>
          <NavbarSpacer />
          <NavbarSection>
            <Dropdown>
              <DropdownButton as={NavbarItem}>
                <Avatar
                  src={
                    user && user.picture_url
                      ? user.picture_url
                      : '/default-profile-pic.png'
                  }
                  square
                />
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="bottom end">
                <DropdownItem onClick={() => navigate('/settings')}>
                  <Cog8ToothIcon />
                  <DropdownLabel>Settings</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem href="https://sandcastles.ai">
                  <ShieldCheckIcon />
                  <DropdownLabel>Privacy policy</DropdownLabel>
                </DropdownItem>
                <DropdownItem onClick={() => setFeedbackOpen(true)}>
                  <LightBulbIcon />
                  <DropdownLabel>Share feedback</DropdownLabel>
                </DropdownItem>
                <DropdownItem onClick={() => setSupportOpen(true)}>
                  <QuestionMarkCircleIcon />
                  <DropdownLabel>Get support</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem
                  onClick={() => {
                    localStorage.removeItem('token')
                    window.location.href = 'https://sandcastles.ai'
                  }}
                >
                  <ArrowRightStartOnRectangleIcon />
                  <DropdownLabel className="text-red-700">
                    Sign out
                  </DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavbarSection>
        </Navbar>
      }
      sidebar={
        <Sidebar
          className={`transition-all duration-300 ${sidebarCollapsed ? 'w-0 opacity-0 overflow-hidden' : 'w-full opacity-100'}`}
        >
          <SidebarHeader>
            <SidebarSection className="max-lg:hidden">
              <div className="flex justify-start items-center mb-4">
                <button
                  onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
                  className="p-2 hover:bg-zinc-200 dark:hover:bg-zinc-700 rounded-lg transition-colors"
                >
                  <ChevronLeftIcon className="w-5 h-5 text-zinc-400" />
                </button>
                <SidebarItem
                  key="sandcastles-logo"
                  href={'/'}
                  onClick={() => setSelectedUuid(undefined)}
                >
                  <Avatar src="/sandcastles-logo-icon.png" />
                  <SidebarLabel className="font-semibold">
                    Sandcastles
                  </SidebarLabel>
                </SidebarItem>
              </div>
              <Button
                className="hover:cursor-pointer w-full mb-1"
                onClick={() => {
                  navigate('/')
                  setSelectedUuid(undefined)
                }}
                color={'blue'}
              >
                <PencilSquareIcon />
                New story
              </Button>
            </SidebarSection>
            <SidebarSection className="lg:hidden">
              <Button
                className="hover:cursor-pointer w-full mb-1"
                onClick={() => {
                  navigate('/')
                  setSelectedUuid(undefined)
                }}
                color={'blue'}
              >
                <PencilSquareIcon />
                New story
              </Button>
            </SidebarSection>
          </SidebarHeader>
          <SidebarBody>
            <SidebarSection>
              <InputGroup>
                <MagnifyingGlassIcon />
                <Input
                  placeholder="Search"
                  className={clsx([
                    'before:bg-zinc-100',
                    'before:shadow-none before:bg-transparent',
                    'focus-within:before:shadow-md focus-within:before:bg-white dark:focus-within:before:bg-white/5',
                  ])}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
              {stories &&
                Object.values(stories).map((storyGroup) => (
                  <div key={storyGroup.label}>
                    <SidebarHeading className={'mt-4'}>
                      {storyGroup.label}
                    </SidebarHeading>
                    {storyGroup.values.map((story) => {
                      return (
                        <SidebarItem
                          key={story.uuid}
                          href={`/s/${story.uuid}?topic=${story.topic}&engine=Research`}
                          onClick={() => setSelectedUuid(story.uuid)}
                          current={selectedUuid === story.uuid}
                          className={'group'}
                        >
                          {story.topic}
                          <button
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              setUuidToDelete(story.uuid)
                              setDeleteModalOpen(true)
                            }}
                            className={
                              'hidden group-hover:flex hover:bg-zinc-300 dark:hover:bg-zinc-700 p-1 rounded-md ml-auto'
                            }
                          >
                            <TrashIcon className={'w-3 h-3 text-red-500'} />
                          </button>
                        </SidebarItem>
                      )
                    })}
                  </div>
                ))}
            </SidebarSection>
            <SidebarSpacer />
          </SidebarBody>
          <SidebarFooter className="max-lg:hidden">
            <Dropdown>
              <DropdownButton as={SidebarItem} key="user-dropdown">
                <span className="flex min-w-0 items-center gap-3">
                  <Avatar
                    src={
                      user && user.picture_url
                        ? user.picture_url
                        : '/default-profile-pic.png'
                    }
                    className="size-10"
                    square
                    alt=""
                  />
                  <span className="min-w-0">
                    <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
                      {user ? user.first_name : 'Loading...'}
                    </span>
                    <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
                      {user ? user.email : ''}
                    </span>
                  </span>
                </span>
                <ChevronUpIcon />
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="top start">
                <DropdownItem onClick={() => navigate('/settings')}>
                  <Cog8ToothIcon />
                  <DropdownLabel>Settings</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem href="https://sandcastles.ai">
                  <ShieldCheckIcon />
                  <DropdownLabel>Privacy policy</DropdownLabel>
                </DropdownItem>
                <DropdownItem onClick={() => setFeedbackOpen(true)}>
                  <LightBulbIcon />
                  <DropdownLabel>Share feedback</DropdownLabel>
                </DropdownItem>
                <DropdownItem onClick={() => setSupportOpen(true)}>
                  <QuestionMarkCircleIcon />
                  <DropdownLabel>Get support</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem
                  onClick={() => {
                    localStorage.removeItem('token')
                    window.location.href = `https://auth.sandcastles.ai/user_management/sessions/logout?session_id=${user.latest_session_id}`
                  }}
                >
                  <ArrowRightStartOnRectangleIcon />
                  <DropdownLabel className="text-red-700">
                    Sign out
                  </DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </SidebarFooter>
        </Sidebar>
      }
    >
      {children}
      <Feedback isOpen={feedbackOpen} setIsOpen={setFeedbackOpen} />
      <Support isOpen={supportOpen} setIsOpen={setSupportOpen} />
      <DeleteModal
        isOpen={deleteModalOpen}
        setIsOpen={setDeleteModalOpen}
        onDelete={deleteStory}
      />
    </SidebarLayout>
  )
}
