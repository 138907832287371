import { Subheading } from '../../components/heading.jsx'
import { Text, TextLink } from '../../components/text.jsx'
import { useState, useEffect } from 'react'
import { Input } from '../../components/input.jsx'
import { Button } from '../../components/button.jsx'
import {
  PlusCircleIcon,
  TrashIcon,
  LightBulbIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid/index.js'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline/index.js'
import Loader from '../../components/loader.jsx'
import { BadgeButton } from '../../components/badge.jsx'
import {
  Dialog,
  DialogTitle,
  DialogDescription,
  DialogBody,
  DialogActions,
} from '../../components/dialog.jsx'
import { lineWobble } from 'ldrs'
import clsx from 'clsx'

lineWobble.register()

const SourceAnalysisStatus = () => {
  const [text, setText] = useState('Loading URL...')
  const messages = [
    'Loading URL...',
    'Checking for video / audio...',
    'Extracting text...',
    'Reading content...',
    'Analyzing content...',
    'Summarizing key points...',
    'Double checking results...',
    'Finishing up, give us a moment...',
  ]

  useEffect(() => {
    let index = 0
    const interval = setInterval(() => {
      if (index < messages.length - 1) {
        index++
        setText(messages[index])
      }
    }, 7500)

    return () => clearInterval(interval)
  }, [])

  return text
}

const AddResearchDialog = ({ open, onClose, onAdd }) => {
  const [text, setText] = useState('')

  const handleSubmit = () => {
    onAdd({ text })
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add research</DialogTitle>
      <DialogDescription>
        Got some interesting research of your own? Share it here, and we'll use
        it in the script.
      </DialogDescription>
      <DialogBody>
        <Input
          placeholder="Enter an interesting research point"
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit()
            }
          }}
        />
      </DialogBody>
      <DialogActions>
        <Button outline onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Add</Button>
      </DialogActions>
    </Dialog>
  )
}

const ReanalyzeDialog = ({ open, onClose, onSubmit }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Re-analyze</DialogTitle>
      <DialogDescription>
        Deleted some sources? Or want to consolidate and de-duplicate the
        research? You've come to the right place.{' '}
      </DialogDescription>
      <DialogDescription>
        PS - Don't worry about your manual research. We'll save those.
      </DialogDescription>
      <DialogActions>
        <Button outline onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            onSubmit()
            onClose()
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const SourceCard = ({
  source,
  onAdd,
  deleteSource,
  outline = false,
  loader = false,
}) => {
  let hostname = null
  if (!loader) {
    hostname = new URL(source.url).hostname
  }
  const favicon = `https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://${hostname}&size=32`
  const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)')
  const color = darkModeQuery.matches ? 'white' : 'black'

  const words = source && source.text ? source.text?.split(' ').length : 0

  return (
    <div
      className={clsx(
        'h-full sm:min-w-[200px] sm:max-w-[200px] w-full px-2 py-1 sm:mx-1 sm:my-0 my-1 rounded-lg group flex flex-col justify-between animate-fadeIn',
        loader &&
          'border-dashed border-2 border-zinc-200 dark:border-zinc-700 animate-pulse',
        outline &&
          'border-dashed border-2 border-zinc-200 dark:border-zinc-700',
        !outline &&
          'bg-zinc-200/50 border-2 border-zinc-200/0 shadow-md dark:bg-zinc-700/50 dark:border-zinc-200/0',
      )}
    >
      <div className={'w-full h-1/3 truncate dark:text-white'}>
        {loader && (
          <Text className={'sm:text-xs max-w-full truncate'}>Loading...</Text>
        )}
        {!loader && (
          <TextLink
            className={'text-xs max-w-full truncate'}
            href={source.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {source.title || source.url}
          </TextLink>
        )}
      </div>
      <div
        className={
          'w-full h-1/3 flex flex-row items-center truncate animate-fadeIn'
        }
      >
        {!loader && !source.loading && (
          <>
            <img className={'w-4 h-4 mr-2'} alt="" src={favicon} />
            <Text
              className={clsx(
                'truncate sm:text-xs text-xs animate-fadeIn',
                !outline && 'group-hover:hidden',
              )}
            >
              {hostname}
            </Text>
            <Text
              className={clsx(
                'truncate sm:text-xs text-xs hidden animate-fadeIn',
                !outline && 'group-hover:flex ',
              )}
            >
              {words} words analyzed
            </Text>
          </>
        )}
        {!loader && source.loading && (
          <div className={'flex flex-row items-center truncate'}>
            <div className={'mr-3'}>
              <l-line-wobble
                size="40"
                stroke="5"
                bg-opacity="0.1"
                speed="1.75"
                color={color}
              />
            </div>
            <Text className={'mt-2 sm:text-xs truncate'}>
              <SourceAnalysisStatus />
            </Text>
          </div>
        )}
      </div>
      <div className={'w-full h-1/3'}>
        {!loader && outline && (
          <>
            <div
              className={
                'flex flex-row items-center group-hover:hidden animate-fadeIn'
              }
            >
              <BadgeButton color={'purple'}>Suggested</BadgeButton>
            </div>
            <div
              className={
                'flex-row items-center hidden group-hover:flex animate-fadeIn'
              }
            >
              <BadgeButton
                onClick={() => onAdd({ url: source.url, suggested: true })}
              >
                Analyze
                <PlusCircleIcon className="w-4 h-4" />
              </BadgeButton>
            </div>
          </>
        )}
        {!loader && source.loading === true && (
          <div className={'flex flex-row items-center animate-fadeIn'}>
            <BadgeButton color={'amber'} className={'mt-1 mr-2 animate-pulse'}>
              Analyzing
            </BadgeButton>
          </div>
        )}
        {!loader && !outline && !(source.loading === true) && (
          <div className="flex flex-row items-center">
            <BadgeButton
              color={'green'}
              className={'group-hover:hidden animate-fadeIn'}
            >
              Analyzed
            </BadgeButton>
            <BadgeButton
              color={'red'}
              className={'hidden group-hover:flex animate-fadeIn'}
              onClick={() => {
                deleteSource({ url: source.url })
              }}
            >
              Delete
              <TrashIcon className="w-4 h-4" />
            </BadgeButton>
          </div>
        )}
      </div>
    </div>
  )
}

const ErrorSourceCard = ({ getSuggestedSources }) => {
  return (
    <div
      className={clsx(
        'h-full sm:min-w-[200px] sm:max-w-[200px] w-full px-2 py-1 sm:mx-1 sm:my-0 my-1 rounded-lg group flex flex-col justify-between animate-fadeIn',
        'border-dashed border-2 border-zinc-200 dark:border-zinc-700',
      )}
    >
      <div className={'w-full h-1/3 truncate dark:text-white'}>
        <TextLink
          className={'text-xs max-w-full truncate hover:cursor-text'}
          disabled
        >
          Unable to load suggestions
        </TextLink>
      </div>
      <div
        className={
          'w-full h-1/3 flex flex-row items-center truncate animate-fadeIn'
        }
      ></div>
      <div className={'w-full h-1/3'}>
        <div
          className={
            'flex flex-row items-center group-hover:hidden animate-fadeIn'
          }
        >
          <BadgeButton color={'red'}>Error</BadgeButton>
        </div>
        <div
          className={
            'flex-row items-center hidden group-hover:flex animate-fadeIn'
          }
        >
          <BadgeButton onClick={getSuggestedSources}>
            Retry
            <PlusCircleIcon className="w-4 h-4" />
          </BadgeButton>
        </div>
      </div>
    </div>
  )
}

const ResearchListItem = ({ research, onDelete }) => {
  return (
    <div
      className={
        'w-full flex flex-row items-start justify-between animate-fadeIn my-1 border-2 overflow-x-hidden bg-zinc-50 dark:bg-zinc-700 border-zinc-100 dark:border-zinc-600 rounded-lg px-3 py-2 relative group'
      }
    >
      <Text>{research.text}</Text>
      <Button
        plain
        onClick={onDelete}
        className="'absolute -top-1 -right-2 p-1 rounded-full bg-white dark:bg-zinc-800 border-2 border-zinc-100 dark:border-zinc-700 hover:bg-zinc-50 dark:hover:bg-zinc-600 opacity-0 group-hover:opacity-100 transition-opacity'"
      >
        <TrashIcon className="w-4 h-4 " />
      </Button>
    </div>
  )
}

const ResearchList = ({
  research,
  researchLoading,
  deleteResearch,
  suggestedSourcesLoading,
  setDrawerOpen,
  confirmReanalyze,
  nextEngine,
}) => {
  if (researchLoading) {
    return <Loader />
  }

  return (
    <div className={'w-full'}>
      <div
        className={
          'flex flex-col sm:flex-row justify-between items-center mb-1'
        }
      >
        <div className={'flex flex-row items-center'}>
          <Subheading>Key Points</Subheading>
          <button
            className="group relative flex items-center"
            aria-label="Help information"
          >
            <QuestionMarkCircleIcon className="w-5 h-5 ml-2 text-zinc-400 hover:text-zinc-600 dark:text-zinc-500 dark:hover:text-zinc-300" />
            <span className="absolute top-0 left-full mb-2 mr-2 z-10 hidden group-hover:block w-80 p-2 text-xs text-zinc-600 dark:text-zinc-300 bg-white dark:bg-zinc-800 rounded-lg shadow-lg border border-zinc-200 dark:border-zinc-700">
              We'll use the information below to write the script. If there are
              points you don't want us to use, you can delete them now.
            </span>
          </button>
        </div>
        <div
          className={
            'flex flex-col sm:flex-row items-center w-full sm:w-auto gap-2 sm:gap-0'
          }
        >
          <Button
            plain
            onClick={() => setDrawerOpen(true)}
            className={'sm:mr-2 w-full sm:w-auto'}
          >
            <PlusCircleIcon className="w-4 h-4 mr-1" />
            Add your own research
          </Button>
          <Button
            outline
            onClick={() => confirmReanalyze(true)}
            className={'sm:mr-2 w-full sm:w-auto'}
          >
            Re-analyze
          </Button>
          <Button
            color={'blue'}
            onClick={nextEngine}
            disabled={research.length < 1}
            className={'w-full sm:w-auto'}
          >
            Finish research
          </Button>
        </div>
      </div>
      <div className={'flex flex-col items-start justify-center'}>
        {research &&
          research.sort((a, b) => a.text.localeCompare(b.text)) &&
          research.map((research, i) => {
            return (
              <ResearchListItem
                research={research}
                key={i}
                onDelete={() => deleteResearch({ researchUuid: research.uuid })}
              />
            )
          })}
      </div>
      {(!research || research.length === 0) && suggestedSourcesLoading && (
        <Text className="py-40 text-center">
          We're finding some sources to get you started. Give us a moment. In
          the meantime, you can add links if you've already started your own
          research.
        </Text>
      )}
      {(!research || research.length === 0) && !suggestedSourcesLoading && (
        <Text className="py-40 text-center">
          No research yet. Add some sources for us to analyze, or add some
          research points manually.
        </Text>
      )}
    </div>
  )
}

const SourceHeader = ({
  sources,
  sourcesLoading,
  saveSource,
  getSuggestedSources,
  suggestedSources,
  suggestedSourcesLoading,
  suggestedSourcesError,
  deleteSource,
  featureFlags,
  toggleFeatureFlag,
}) => {
  const [url, setUrl] = useState('')

  const showHint =
    featureFlags?.['show_research_hint'] === undefined
      ? true
      : featureFlags['show_research_hint']

  return (
    <div className={'animate-fadeIn'}>
      {showHint && (
        <div
          className={
            'hidden sm:flex flex-row items-center border-2 border-orange-200 dark:border-amber-400/80 rounded-lg p-2 animate-fadeIn'
          }
        >
          <LightBulbIcon className="w-9 h-9 ml-2 mr-4 text-orange-300 dark:text-amber-400" />
          <Text
            className={
              'line-clamp-3 hover:line-clamp-none italic sm:text-xs pr-2'
            }
          >
            All good scripts start with research. The key points we identify
            below will form the foundation of the final script. We've suggested
            some sources that are relevant, and we can analyze them if you
            approve. If you have your own links, now is the perfect time to add
            them too. We'll pull out the most interesting points for you to
            review and edit below. You can delete any that you don't like. When
            you're satisfied, we'll move on to the next step.
          </Text>
          <Button
            plain
            onClick={() => {
              toggleFeatureFlag({ flag: 'show_research_hint', value: false })
            }}
          >
            <XMarkIcon className="w-9 h-9" />
          </Button>
        </div>
      )}
      <div
        className={'flex sm:flex-row flex-col animate-fadeIn sm:h-[100px] my-3'}
      >
        <div
          className={
            'flex flex-col items-center justify-between sm:w-1/6 py-2 mr-1'
          }
        >
          <Input
            placeholder={'Enter a URL'}
            value={url}
            onChange={(e) => {
              let value = e.currentTarget.value
              if (value && !value.startsWith('https://'))
                value = 'https://' + value
              setUrl(value)
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                saveSource({ url: url, manual: false })
                setUrl('')
              }
            }}
          />
          <Button
            className={'mt-1 w-full'}
            onClick={() => {
              saveSource({ url: url, manual: false })
              setUrl('')
            }}
            disabled={url === ''}
          >
            Analyze
          </Button>
        </div>
        <div
          className={
            'flex sm:flex-row flex-col items-center justify-start sm:w-5/6 py-2 overflow-x-auto scrollbar-hide relative'
          }
        >
          {(sourcesLoading || suggestedSourcesLoading) && (
            <SourceCard loader outline />
          )}
          {!sourcesLoading &&
            Object.values(sources).map((source) => {
              return (
                <SourceCard
                  key={source.url}
                  source={source}
                  deleteSource={deleteSource}
                />
              )
            })}
          {!suggestedSourcesLoading && suggestedSourcesError && (
            <ErrorSourceCard getSuggestedSources={getSuggestedSources} />
          )}
          {!suggestedSourcesLoading &&
            suggestedSources &&
            suggestedSources
              .filter(
                (suggestedSource) =>
                  !Object.keys(sources).includes(suggestedSource.url),
              )
              .map((suggestedSource, i) => {
                return (
                  <SourceCard
                    key={i}
                    source={suggestedSource}
                    onAdd={saveSource}
                    outline
                  />
                )
              })}
        </div>
      </div>
    </div>
  )
}

export default function Research({
  show,
  setSelectedEngine,
  sources,
  sourcesLoading,
  research,
  researchLoading,
  deleteResearch,
  addNewResearch,
  getSuggestedSources,
  suggestedSources,
  suggestedSourcesLoading,
  suggestedSourcesError,
  saveSource,
  reanalyzeSources,
  deleteSource,
  featureFlags,
  toggleFeatureFlag,
}) {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [reanalyze, setReanalyze] = useState(false)

  if (!show) return null

  return (
    <div className="h-full">
      <AddResearchDialog
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onAdd={addNewResearch}
      />
      <ReanalyzeDialog
        open={reanalyze}
        onClose={() => setReanalyze(false)}
        onSubmit={reanalyzeSources}
      />
      <SourceHeader
        sources={sources}
        sourcesLoading={sourcesLoading}
        saveSource={saveSource}
        getSuggestedSources={getSuggestedSources}
        suggestedSources={suggestedSources}
        suggestedSourcesLoading={suggestedSourcesLoading}
        suggestedSourcesError={suggestedSourcesError}
        deleteSource={deleteSource}
        featureFlags={featureFlags}
        toggleFeatureFlag={toggleFeatureFlag}
      />
      <div
        className={
          'flex sm:flex-row flex-col sm:bg-white bg-zinc-100 dark:bg-zinc-800 rounded-xl shadow-sm dark:shadow-lg p-4 animate-fadeIn'
        }
      >
        <ResearchList
          research={research}
          researchLoading={researchLoading}
          deleteResearch={deleteResearch}
          suggestedSourcesLoading={suggestedSourcesLoading}
          setDrawerOpen={setDrawerOpen}
          confirmReanalyze={setReanalyze}
          nextEngine={() => setSelectedEngine('Angle')}
        />
      </div>
    </div>
  )
}
