import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Heading } from '../../components/heading.jsx'
import Page from '../../components/page.jsx'
import Status from './status.jsx'
import Research from './researchDetail.jsx'
import ScriptDetail from './scriptDetail.jsx'
import apiClient from '../../utils/apiClient.js'
import LensDetail from './lensDetail.jsx'
import Nav from './nav.jsx'
import { generateUUID } from '../../utils/uuid.jsx'

export default function Detail({ user, setUser }) {
  // window and path functions ================================================================
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const params = Object.fromEntries(searchParams.entries())
  const pathHeading =
    params && params.topic
      ? params.topic
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
      : 'Detail'
  const pathUuid = location.pathname.split('/')[2]
  const pathEngine = params && params.engine

  // local state ================================================================
  const [featureFlags, setFeatureFlags] = useState(user.feature_flags)
  const [heading, setHeading] = useState(pathHeading)
  const [uuid, setUuid] = useState(pathUuid)
  const [status, setStatus] = useState('draft')
  const [selectedEngine, setSelectedEngine] = useState(pathEngine)
  const [sources, setSources] = useState({})
  const [sourcesLoading, setSourcesLoading] = useState(true)
  const [suggestedSources, setSuggestedSources] = useState([])
  const [suggestedSourcesLoading, setSuggestedSourcesLoading] = useState(true)
  const [suggestedSourcesError, setSuggestedSourcesError] = useState(false)
  const [research, setResearch] = useState([])
  const [researchLoading, setResearchLoading] = useState(true)
  const [lens, setLens] = useState({})
  const [lensPersona, setLensPersona] = useState('')
  const [lensLoading, setLensLoading] = useState(true)
  const [script, setScript] = useState('')
  const [scriptLoading, setScriptLoading] = useState(false)

  // page setup ================================================================
  useEffect(() => {
    setHeading(pathHeading)
    setUuid(pathUuid)
  }, [pathHeading, pathUuid])

  const changeEngine = (tab) => {
    setSelectedEngine(tab)
    const pathSuffix = `&engine=${tab}`
    const currentPath = window.location.pathname + window.location.search
    const newPath = currentPath.replace(/&engine=[^&]*/, '') + pathSuffix
    window.history.pushState({}, '', newPath)
  }

  useEffect(() => {
    changeEngine(pathEngine)
  }, [pathEngine])

  const toggleFeatureFlag = ({ flag, value }) => {
    setFeatureFlags((currentFlags) => ({ ...currentFlags, [flag]: value }))
    apiClient.post('/auth/feature_flag', { flag, value }).then((resp) =>
      setUser((currentUser) => ({
        ...currentUser,
        feature_flags: resp,
      })),
    )
  }

  // story ================================================================
  useEffect(() => {
    apiClient.get(`story/?story_uuid=${uuid}`).then((resp) => {
      setStatus(resp.status)
    })
  }, [uuid])

  const changeStatus = (status) => {
    setStatus(status)
    apiClient.post('story/status', {
      story_uuid: uuid,
      status: status,
    })
  }

  // sources ================================================================
  const getSources = () => {
    setSourcesLoading(true)
    apiClient.get(`/source/list?story_uuid=${uuid}`).then((resp) => {
      setSources(resp)
      setSourcesLoading(false)
    })
  }

  useEffect(() => {
    if (uuid) {
      getSources()
    }
  }, [uuid])

  const getSuggestedSources = () => {
    setSuggestedSourcesLoading(true)
    apiClient
      .get(`/source/suggested?story_uuid=${uuid}`)
      .then((resp) => {
        setSuggestedSources(resp)
        setSuggestedSourcesLoading(false)
        setSuggestedSourcesError(false)
      })
      .catch(() => {
        setSuggestedSourcesError(true)
        setSuggestedSourcesLoading(false)
      })
  }

  useEffect(() => {
    if (uuid) {
      getSuggestedSources()
    }
  }, [uuid])

  const saveSource = ({ url, suggested = false }) => {
    const newSource = {
      url: url,
      suggested: suggested,
      loading: true,
    }
    setSources((currentSources) => ({
      ...currentSources,
      [newSource.url]: newSource,
    }))
    apiClient
      .post('source/', {
        story_uuid: uuid,
        url: url,
        suggested: suggested,
      })
      .then((resp) => {
        const { source, research } = resp
        setSources((currentSources) => ({
          ...currentSources,
          [source.url]: source,
        }))
        setResearch((currentResearch) => [...currentResearch, ...research])
      })
  }

  const reanalyzeSources = () => {
    setResearchLoading(true)
    apiClient.get(`source/reanalyze/?story_uuid=${uuid}`).then((resp) => {
      setResearch(resp)
      setResearchLoading(false)
    })
  }

  const deleteSource = ({ url }) => {
    setSources(
      Object.fromEntries(
        Object.entries(sources).filter(([sourceUrl, _]) => sourceUrl !== url),
      ),
    )
    apiClient.delete(`source/?story_uuid=${uuid}&url=${url}`)
  }
  // research ================================================================
  const getResearch = () => {
    setResearchLoading(true)
    apiClient.get(`/research/list?story_uuid=${uuid}`).then((resp) => {
      setResearch(resp)
      setResearchLoading(false)
    })
  }

  useEffect(() => {
    if (uuid) {
      getResearch()
    }
  }, [uuid])

  const addNewResearch = ({ text }) => {
    const newUuid = generateUUID()
    const newRow = { uuid: newUuid, text: text, manual: true }
    setResearch((currentResearch) => [...currentResearch, newRow])
    apiClient.post(`research/`, {
      story_uuid: uuid,
      uuid: newUuid,
      text: text,
    })
  }

  const deleteResearch = ({ researchUuid }) => {
    setResearch((currentResearch) =>
      currentResearch.filter((research) => research.uuid !== researchUuid),
    )
    apiClient.delete(
      `research/?story_uuid=${uuid}&research_uuid=${researchUuid}`,
    )
  }

  // lenses ================================================================
  const getLens = () => {
    setLensLoading(true)
    apiClient.get(`lens/active?story_uuid=${uuid}`).then((lens) => {
      if (lens) {
        setLens(lens)
        setLensPersona(lens.persona)
      } else {
        setLens({})
        setLensPersona('')
      }
      setLensLoading(false)
    })
  }

  useEffect(() => {
    if (uuid) {
      getLens()
    }
  }, [uuid])

  const switchLens = (persona) => {
    setLensPersona(persona)
    setLensLoading(true)
    apiClient
      .post(`lens/`, {
        story_uuid: uuid,
        persona: persona,
      })
      .then((lens) => {
        setLens(lens)
        setLensLoading(false)
      })
  }

  const regenerateLens = () => {
    setLensLoading(true)
    apiClient
      .post('lens/regenerate', {
        story_uuid: uuid,
        persona: lensPersona,
      })
      .then((lens) => {
        setLens(lens)
        setLensLoading(false)
      })
  }

  // script ================================================================
  const getScript = ({ regenerate = false }) => {
    setScriptLoading(true)
    apiClient
      .get(`script/?story_uuid=${uuid}&regenerate=${regenerate}`)
      .then((resp) => {
        if (resp && resp.body) {
          setScript(resp.body)
        } else {
          setScript('')
        }
        setScriptLoading(false)
      })
  }

  useEffect(() => {
    if (uuid) {
      getScript({})
    }
  }, [uuid])

  const saveScript = (text) => {
    setScript(text)
    apiClient.post('script/', { story_uuid: uuid, body: text })
  }

  const rewriteScript = ({
    componentToRewrite,
    selectedText,
    instructions,
  }) => {
    setScriptLoading(true)
    apiClient
      .post('script/rewrite', {
        story_uuid: uuid,
        component: componentToRewrite,
        body: selectedText,
        custom_instructions: instructions,
      })
      .then((resp) => {
        setScript(resp.body)
        setScriptLoading(false)
      })
  }

  // components ================================================================
  return (
    <Page>
      <div className="h-30">
        <div className="flex flex-row justify-between items-center">
          <Heading>{heading}</Heading>
          <Status value={status} onChange={changeStatus} />
        </div>
        <Nav changeEngine={changeEngine} selectedEngine={selectedEngine} />
      </div>
      <div className="">
        <Research
          show={selectedEngine === 'Research'}
          setSelectedEngine={changeEngine}
          sources={sources}
          sourcesLoading={sourcesLoading}
          research={research}
          researchLoading={researchLoading}
          deleteResearch={deleteResearch}
          addNewResearch={addNewResearch}
          getSuggestedSources={getSuggestedSources}
          suggestedSources={suggestedSources}
          suggestedSourcesLoading={suggestedSourcesLoading}
          suggestedSourcesError={suggestedSourcesError}
          saveSource={saveSource}
          reanalyzeSources={reanalyzeSources}
          deleteSource={deleteSource}
          featureFlags={featureFlags}
          toggleFeatureFlag={toggleFeatureFlag}
        />
        <LensDetail
          storyUuid={uuid}
          show={selectedEngine === 'Angle'}
          setSelectedEngine={changeEngine}
          lens={lens}
          lensPersona={lensPersona}
          lensLoading={lensLoading}
          switchLens={switchLens}
          regenerateLens={regenerateLens}
          research={Object.keys(research).length}
          featureFlags={featureFlags}
          toggleFeatureFlag={toggleFeatureFlag}
        />
        <ScriptDetail
          storyUuid={uuid}
          show={selectedEngine === 'Script'}
          topic={heading}
          body={script}
          setBody={setScript}
          loading={scriptLoading}
          setSelectedEngine={changeEngine}
          ready={
            Object.keys(research).length > 0 && Object.keys(lens).length > 0
          }
          getScript={getScript}
          saveScript={saveScript}
          rewriteScript={rewriteScript}
        />
      </div>
    </Page>
  )
}
