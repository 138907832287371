import { grid } from 'ldrs'
import clsx from 'clsx'

grid.register()

export default function Loader({ className }) {
  const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)')
  const color = darkModeQuery.matches ? '#FFFFFF' : '#000000'

  return (
    <div
      className={clsx(
        'flex justify-center items-center h-96 w-full bg-none',
        className,
      )}
    >
      <l-grid size="60" speed="1.5" color={color} />
    </div>
  )
}
