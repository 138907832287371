import * as Headless from '@headlessui/react'
import React, { forwardRef } from 'react'
import { NavLink } from 'react-router-dom'

export const Link = forwardRef(function Link(props, ref) {
  return (
    <Headless.DataInteractive>
      <NavLink {...props} ref={ref} to={!props.disabled ? props.href : null} />
    </Headless.DataInteractive>
  )
})
