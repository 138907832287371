import { useState } from 'react'
import Page from '../../components/page.jsx'
import TopicSuggestions from './topicSuggestion.jsx'
import HeroInput from './heroInput.jsx'
import TrendingTopics from './trendingTopics.jsx'
import { useNavigate } from 'react-router-dom'
import { generateUUID } from '../../utils/uuid.jsx'
import apiClient from '../../utils/apiClient.js'
import { capitalize } from '../../utils/string.jsx'
import clsx from 'clsx'
import { Button } from '../../components/button.jsx'

export default function Home({ setRefresh }) {
  const navigate = useNavigate()
  const [topic, setTopic] = useState('')
  const [showTrendingTopics] = useState(false)
  const [showDebugButton] = useState(false)

  const handleChooseTopic = (topic) => {
    setTopic(topic)
    const newUuid = generateUUID()
    apiClient
      .post('story/', {
        story_uuid: newUuid,
        topic: capitalize(topic),
      })
      .then(() => {
        navigate(`/s/${newUuid}?topic=${topic}&engine=Research`)
        setRefresh(true)
      })
  }

  return (
    <Page>
      <div className="h-full w-full flex flex-col relative items-center justify-center">
        <div className="flex flex-col items-center justify-center w-full">
          <h1
            className={clsx(
              'text-4xl text-zinc-950 dark:text-white mt-5 mb-10 text-center',
              showTrendingTopics ? 'mt-20' : '',
            )}
          >
            What do you want to make a video about?
          </h1>
          <HeroInput
            topic={topic}
            setTopic={setTopic}
            handleChooseTopic={handleChooseTopic}
          />
          <TopicSuggestions handleChooseTopic={handleChooseTopic} />
          <Button
            onClick={() => {
              throw new Error('This is your first error!')
            }}
            className={clsx(showDebugButton ? 'mt-6' : 'hidden')}
          >
            Test Sentry
          </Button>
          {showTrendingTopics && (
            <TrendingTopics handleChooseTopic={handleChooseTopic} />
          )}
        </div>
      </div>
    </Page>
  )
}
