import { ArrowUpRightIcon } from '@heroicons/react/20/solid/index.js'
import { Subheading } from '../../components/heading.jsx'

function TopicSuggestion({ name, onClick }) {
  return (
    <div
      onClick={onClick}
      className="ring-1 rounded-full ring-zinc-300 px-3 py-2 ml-2 text-zinc-700 text-sm flex flex-row items-center hover:bg-zinc-200 hover:cursor-pointer dark:text-zinc-300 dark:hover:bg-zinc-700"
    >
      {name}
      <ArrowUpRightIcon className="w-4 h-4 ml-1" />
    </div>
  )
}

export default function TopicSuggestions({ handleChooseTopic }) {
  return (
    <div className="relative w-full flex sm:flex-row flex-col mt-4 gap-3 sm:gap-0 items-center justify-center">
      <Subheading className="opacity-50 font-light text-sm text-zinc-400">
        Ex: I'd like to write a script about
      </Subheading>
      <TopicSuggestion
        name="Tesla Full Self-Driving"
        onClick={() => handleChooseTopic('Tesla Full Self-Driving')}
      />
      <TopicSuggestion
        name="Boom Aerospace"
        onClick={() => handleChooseTopic('Boom Aerospace')}
      />
      <TopicSuggestion
        name="Zipline Drones"
        onClick={() => handleChooseTopic('Zipline Drones')}
      />
    </div>
  )
}
