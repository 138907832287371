import { Button } from '../../components/button.jsx'
import {
  Dialog,
  DialogActions,
  DialogDescription,
  DialogTitle,
} from '../../components/dialog.jsx'

export default function DeleteModal({ isOpen, setIsOpen, onDelete }) {
  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogDescription>
        Are you sure you want to delete this story? This action cannot be
        undone.
      </DialogDescription>
      <DialogActions>
        <Button plain onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            onDelete()
            setIsOpen(false)
          }}
          color={'red'}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}
