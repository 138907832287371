import clsx from 'clsx'
import { Text } from '../../components/text.jsx'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline/index.js'
import { motion } from 'framer-motion'

export default function Nav({ changeEngine, selectedEngine }) {
  return (
    <div className="relative mt-0 mb-3">
      <nav
        className="flex space-x-8 border-b border-zinc-200 dark:border-zinc-700"
        aria-label="Tabs"
      >
        {['Research', 'Angle', 'Script'].map((tab) => (
          <button
            key={tab}
            onClick={() => changeEngine(tab)}
            className={clsx(
              'relative py-2 px-1 text-sm font-medium transition-colors duration-200 w-24',
              selectedEngine === tab
                ? 'text-black dark:text-white'
                : 'text-zinc-500 hover:text-zinc-700 dark:text-zinc-400 dark:hover:text-zinc-300',
            )}
          >
            {tab}
          </button>
        ))}
        <div className="hidden sm:flex-1 sm:flex justify-end">
          <button
            className="group relative flex items-center"
            aria-label="Help information"
          >
            {' '}
            <Text
              className={
                'mr-1 text-zinc-400 hover:text-zinc-600 dark:text-zinc-500 dark:hover:text-zinc-300'
              }
            >
              Help
            </Text>
            <QuestionMarkCircleIcon className="w-5 h-5 text-zinc-400 hover:text-zinc-600 dark:text-zinc-500 dark:hover:text-zinc-300" />
            <span className="absolute bottom-0 right-full mb-2 mr-2 hidden group-hover:block w-80 p-2 text-xs text-zinc-600 dark:text-zinc-300 bg-white dark:bg-zinc-800 rounded-lg shadow-lg border border-zinc-200 dark:border-zinc-700">
              {selectedEngine === 'Research' &&
                "We're starting the script writing process by doing research. We'll analyze links that you provide and links that we've suggested (if you approve). Then we'll pull out the most interesting information for your review. Our goal is to be satisfied will all the key research points below before moving on."}
              {selectedEngine === 'Angle' &&
                "We've generated some unique POV angles for you. Pick the one you find most interesting, and we'll write a script from that angle."}
              {selectedEngine === 'Script' &&
                "We've written a script for you. Feel free to edit and tweak as much as you'd like. When you're satisfied, set the status to `Ready`."}
            </span>
          </button>
        </div>
      </nav>
      <motion.div
        className="absolute bottom-0 h-0.5 bg-black dark:bg-white"
        initial={false}
        animate={{
          left: ['Research', 'Angle', 'Script'].indexOf(selectedEngine) * 128,
          width: 96,
        }}
        transition={{
          type: 'spring',
          stiffness: 400,
          damping: 30,
        }}
      />
    </div>
  )
}
